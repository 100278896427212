// Este componenete solo se creo para evitar la repetición de código
import React from "react";
import RoundedComponent from "../atoms/RoundedComponent";

export default function SelectPower() {
    return (
        <div>

            <RoundedComponent color="#e7ff00">
                <select
                    name="option"
                    className="form-control border-0"
                    style={{ backgroundColor: "transparent", color: "black" }}
                >
                    <option>Select</option>
                </select>
            </RoundedComponent>
        </div >
    );
}