import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import RoundedComponent from "../atoms/RoundedComponent";

export default function SerialID_Respawn(props) {
    return (
        <div style={{ marginTop: "25px" }} >
            <RoundedComponent color="#e7ff00">
                <div className="input-group ">
                    <div className="input-group-append btn-mail">
                        <div>
                            <FontAwesomeIcon icon="paper-plane" color="transparent" />
                        </div>
                    </div>
                    <input
                        placeholder={props.placeholder}
                        className="form-control border-0"
                        style={{ backgroundColor: "transparent", color: "black", textAlign: "center" }}
                    />
                </div>
            </RoundedComponent>
            <p />
            <RoundedComponent color="#B0F7E1">
                <div className="input-group ">
                    <div className="input-group-append btn-mail">
                        <div>
                            <FontAwesomeIcon icon="paper-plane" color="transparent" />
                        </div>
                    </div>
                    <button
                        placeholder={props.placeholder}
                        className="form-control border-0"
                        style={{ backgroundColor: "transparent", color: "black", textAlign: "center", textTransform: "uppercase" }}
                    >
                        Respawn
                    </button>
                </div>
            </RoundedComponent>
        </div >
    );
}