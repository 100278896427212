import React from "react";

export default function SectionHeader(props) {
    return (
        <div
            className={`h2 col-12 iceberg title-3 ml-xl-0 ml-md-5 ml-2 ${props.className ?? "mt-4 mb-0"}`}
            style={{ color: "#e7ff00" }}
        >
            {`${props.children}`.toUpperCase()}
        </div>
    );
}
