import moment from "moment-timezone";
import React, { Component } from "react";
import { readConfig } from "../../redux/blockchain/readConfig";

moment.tz.setDefault("America/Mexico_City");

export default class Counter extends Component {
    state = {
        days: 69,
        hours: 17,
        minutes: 5,
        seconds: 0,
        hasEnded: false,
        hasLoaded: false,
    };

    async componentDidMount() {
        const CONFIG = await readConfig();
        this.setCountDown(CONFIG.LAUNCH_DATE, this.props.format ? this.props.format : "DD-MM-YYYY-HH:mm");
    }

    render() {
        return (
            <span className={this.props.className + (!this.state.hasLoaded ? " text-warning" : " text-black")}>
                {this.formatNumber(this.state.days)}d. {this.formatNumber(this.state.hours)}h.{" "}
                {this.formatNumber(this.state.minutes)}m. {this.formatNumber(this.state.seconds)}s.
            </span>
        );
    }

    countDown() {
        let hasEnded = false;
        let days = this.state.days;
        let hours = this.state.hours;
        let minutes = this.state.minutes;
        let seconds = this.state.seconds;
        seconds -= 1;
        if (seconds < 0) {
            minutes -= 1;
            seconds = 59;
        }
        if (minutes < 0) {
            hours -= 1;
            minutes = 59;
        }
        if (hours < 0) {
            days -= 1;
            hours = 23;
        }
        if (days < 0) {
            hasEnded = true;
            days = 0;
            hours = 0;
            minutes = 0;
            seconds = 0;
        }
        this.setState(() => ({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
            hasEnded: hasEnded,
        }));
        if (!hasEnded) {
            setTimeout(() => {
                this.countDown();
            }, 1000);
        } else {
            this.props.whenEnded();
        }
    }
    setCountDown(date, format) {
        const now = moment().tz("America/Mexico_City");
        const end = moment(date, format).tz("America/Mexico_City");
        const duration = moment.duration(end.diff(now));
        this.setState(
            () => ({
                days: duration.months() * 30 + duration.days(),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds(),
                hasLoaded: true,
            }),
            () => this.countDown()
        );
    }

    formatNumber(num) {
        let formatted = `${num}`;
        if (formatted.length < 2) {
            return "0" + formatted;
        }
        return formatted;
    }

    isOver() {
        return this.state.hasEnded;
    }
}
