import React from "react";

export default function ImagePattern(props) {
    return (
        <div
            style={{
                backgroundImage: `url("${props.src}")`,
                backgroundRepeat: "space",
                height: props.height,
            }}
            className={`w-100 ${props.className ?? props.className}`}
        />
    );
}
