import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/organisms/Footer";
import Navbar from "./components/organisms/Navbar";
import MainView from "./views/MainView";
import StartingView from "./views/StartingView";
import AppOld from "./AppOld";
import { readConfig } from "./redux/blockchain/readConfig";
import { useState } from "react";
import moment from "moment-timezone";
import NFTView from "./views/NFTView";
import RarityChart from "./views/RarityChart";

moment.tz.setDefault("America/Mexico_City");

export default function App() {
    const [canMint, changeMint] = useState(false);
    checkIsTime().then((isTime) => {
        changeMint(isTime);
    });
    return (
        <div className="page-container">
            <div className="content-wrap">
                <Navbar />
                {/* <div style={{ position: "absolute", color: "#FF1694", zIndex: 10000 }}>
                    <div className="d-none d-xl-block font-weight-bold">X-LARGE (XL)</div>
                    <div className="d-none d-lg-block d-xl-none font-weight-bold">LARGE (LG)</div>
                    <div className="d-none d-md-block d-lg-none font-weight-bold">MEDIUM (M)</div>
                    <div className="d-none d-sm-block d-md-none font-weight-bold">SMALL (SM)</div>
                    <div className="d-block d-sm-none alert font-weight-bold">X-SMALL (Defaut)</div>
                </div> */}
                <Routes>
                    <Route path="/" element={<StartingView />} />
                    <Route path="/start" element={<MainView />} />
                    <Route path="/nft" element={<NFTView />} />
                    {canMint && <Route path="/mint" element={<AppOld />} />}
                    <Route path="/raritychart" element={<RarityChart />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

async function checkIsTime() {
    const CONFIG = await readConfig();
    const worldtime = await fetch("https://worldtimeapi.org/api/timezone/America/Mexico_City");
    const now = moment(worldtime.datetime);
    const launchDate = moment(CONFIG.LAUNCH_DATE, "DD-MM-YYYY-HH:mm").tz("America/Mexico_City");
    return now.diff(launchDate) >= 0;
}
