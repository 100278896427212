let config = null;

export const readConfig = async () => {
    if (config == null) {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        config = await configResponse.json();
    }
    return config;
};
