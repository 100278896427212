import React from "react";
import LineBreak from "../atoms/LineBreak";
import SectionHeader from "../atoms/SectionHeader";
import RoadmapRow from "../molecules/RoadmapRow";

export default function RoadMap() {
    return (
        <div className="row pt-md-0 pt-3" id="Roadmap">
            <SectionHeader>Roadmap.</SectionHeader>
            <div className="pt-xl-3 col-lg-11 col-12 mx-auto">
                <div className="px-3">
                    <table className="table col-lg-9 col-12 border-0 my-0">
                        <tbody>
                            <RoadmapRow percentage="10%">
                                After our initial Drop, some lucky BearLab. NFT Holders will be awarded with 1 Teddy,
                                airdropped directly to their Metamask wallet.
                            </RoadmapRow>
                            <RoadmapRow percentage="20%">
                                BearLab. will grant members exclusive pre-sale access to Lab.Merch, special Members-Only
                                Merch gets unlocked.
                            </RoadmapRow>
                            <RoadmapRow percentage="30%">
                                Monsieur-Croque`s Musical-Theme gets unveiled, and will only ever be hearable to Members
                                within the Society.
                            </RoadmapRow>
                            <RoadmapRow percentage="40%">
                                A Batch of 10 Special-Eddition Teddies will be donated to help ease the Peace Efforts in
                                Ukraine.
                                <br />
                                All the Proceeds will be directly Transfered to :
                                <span className="text-warning"> UNICEF [www.unicef.org] </span>
                            </RoadmapRow>
                            <RoadmapRow percentage="60%">
                                MAYDAY !
                                <div className="col-12 my-md-0 my-3" />
                                The quest begins ! First 99º Holders to solve Monsieur-Croque's cryptic code will enter
                                the META-Quest Shortlist.
                                <br />
                                The CODE will grant you Access :<br />
                                <div className="d-inline-block pr-lg-5 pr-0" />
                                <div className="d-inline-block d-lg-none">☞&nbsp;</div>Decode the Message&nbsp;
                                <LineBreak size="xs" limit="md" />☞ Open the Mystery Vault&nbsp;
                                <LineBreak size="xs" limit="md" />☞ Await further Instructions.
                                <div className="col-12 my-md-0 my-2" />
                            </RoadmapRow>
                            <RoadmapRow percentage="80%">
                                BearLab. Experimental Society gets unleashed:
                                <div className="col-12 my-md-0 my-3" />
                                -Access to Members-Only private events.
                                <br />
                                -Collaborations with Household Brands.
                                <br />
                                -Croque`s Fashion Runway is activated.
                                <br />
                                - 33º Lucky Holders will receive receive a physical Canvas of a Teddy, painted and
                                signed by Croque.
                                <div className="col-12 my-md-3" />
                            </RoadmapRow>
                            <RoadmapRow percentage="90%">
                                Monsieur-Croque`s Crypto-Token enters the Market.
                                <br />
                                To inaugurate it, all Members aboard will be airdropped 1.00 BearLab-Coin.
                            </RoadmapRow>
                            <RoadmapRow percentage="100%">
                                <div className="col-lg-10 col-md-11 col-12 px-0 mx-0">
                                    Croque`s Big Revelation !
                                    <div className="col-12 my-md-0 my-3" />
                                    -Members-Only Private Party in an Exotic Location around the Globe.
                                    <div className="col-12 my-md-0 my-3" />
                                    -The Winner of the META-Quest will acquire Monsieur-Croque`s one-and-only
                                    Golden-Relic.
                                </div>
                            </RoadmapRow>
                            <RoadmapRow>
                                <div className="text-right">
                                    <img src="/img/crate.gif" className="crate" />
                                </div>
                                <div className="text-warning col-12 px-0 pb-md-0 pb-4">PHASE II</div>
                            </RoadmapRow>
                        </tbody>
                    </table>
                </div>
                <div className="w-100 px-md-5 px-0 ">
                    <div className="px-lg-5 mx-lg-5">
                        <div className="px-lg-3">
                            <div className="blur mx-auto px-0">
                                <img src="/img/blurry-locked.png" className="w-100 px-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
