import React from "react";
import SectionHeader from "../atoms/SectionHeader";
import ImageRow from "../molecules/ImageRow";

export default function Lore() {
    return (
        <div className="row mt-md-5 pb-2 pb-md-0">
            <SectionHeader>Croque's Lore.</SectionHeader>
            <div className="mx-auto px-md-5 px-3 pt-md-3">
                <ImageRow
                    images={[
                        { src: "/img/Lot-59.png" },
                        { src: "/img/Lot-61.png" },
                        { src: "/img/Lot-60.png" },
                        { src: "/img/Lot-62.png" },
                        { src: "/img/Lot-63.png" },
                    ]}
                />
            </div>
            <div className="text-center text-p row mx-auto mt-lg-3 mt-md-2 mt-0 px-md-2 px-3">
                <div className="col-12 mx-auto mt-4">
                    <span className="text-warning">Monsieur-Croque</span>
                    &nbsp;is  a Pop-Artist  who criticizes society's current upheavals  through the concoction of Synthetic Mimicry {"&"} the Plastic use of Pixel Synthesis.
                </div>
                <div className="col-12 mx-auto mt-md-0 mt-4">
                    During the upcoming Renaissance of Digital Art, Croque aspires to reflect our societal Ethos  by Creating a Parallel Universe between our postmodernist,
                    hyperconnected entities, {"&"} the imminent loss of our identities.
                </div>
                <div className="col-12 mx-auto my-md-4 py-md-2 my-4">
                    By Deifying Human-like Bears,{" "}
                    <span className="text-warning">Monsieur-Croque`s Lab. Experiment</span> seeks to return humanity to
                    our inherently cultural souls.
                </div>
                <div className="col-12 mb-md-5 pb-md-3 mb-4">
                    Stretching his hand towards Technology, he sees himself as a defiant <br />Prometheus providing fire to
                    willing Humans.
                </div>
            </div>
        </div>
    );
}
