import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import CircleBtn from "../molecules/CircleBtn";
import { Link, useLocation } from "react-router-dom";
import { readConfig } from "../../redux/blockchain/readConfig";
import { useEffect } from "react";
import cheerio from "cheerio";

export default function Navbar() {
    const location = useLocation();
    const [discord, setDiscord] = useState("");
    useEffect(async () => {
        const link = await getDiscordLink();
        setDiscord(link);
    }, []);

    return (
        <>
            <nav className="navbar navbar-light w-100 fixed-top" style={{ backgroundColor: "#e7ff00" }}>
                <div className="col-12 row mx-0 px-0">
                    <div className="col px-0">
                        {location.pathname !== "/" ? (
                            <Link to={location.pathname === "/start" ? "/" : "/start"}>
                                <FontAwesomeIcon
                                    icon="chevron-left"
                                    size="lg"
                                    style={{ height: "100%", color: "black" }}
                                />
                            </Link>
                        ) : (
                            <FontAwesomeIcon icon="chevron-left" size="lg" color="transparent" />
                        )}
                    </div>
                    <div
                        className="ml-auto mr-0 px-0 text-right row col-auto nav-buttons"
                        style={{ display: "contents" }}
                    >
                        <CircleBtn
                            className="ml-auto mr-1"
                            background="white"
                            icon={["fab", "discord"]}
                            iconColor="black"
                            href={discord}
                        />
                        <CircleBtn
                            className="mx-1"
                            background="white"
                            icon={["fab", "twitter"]}
                            iconColor="black"
                            href="https://twitter.com/bearlab_nft"
                        />
                        <CircleBtn
                            className="mx-1"
                            background="white"
                            icon={["fab", "instagram"]}
                            iconColor="black"
                            href="https://www.instagram.com/bearlab.nft/"
                        />
                        <CircleBtn
                            className="ml-1"
                            background="white"
                            img="/img/opensea.png"
                            iconColor="black"
                            href="https://opensea.io/BearLab"
                        />
                    </div>
                </div>
            </nav>
            <div className="d-block py-2 opacity-0">
                <CircleBtn className="mx-1" background="white" icon={["fab", "instagram"]} iconColor="black" />
            </div>
        </>
    );
}

async function getDiscordLink() {
    try {
        const response = await fetch("/linktree.php");
        const text = await response.text();
        const scapper = cheerio.load(text);
        const data = scapper("#__NEXT_DATA__")[0].children[0].data;
        const tree = JSON.parse(data);
        const links = tree.props.pageProps.links;
        for (const link of links) {
            if (link.title == "Discord") {
                return link.url;
            }
        }
    } catch {
        const config = await readConfig();
        return config.DISCORD;
    }
}
