import React from "react";

export default function GraphicPower() {
    return (
        <div className="rarity-chart-graphics">
            <div className="row ">
                <div className="col d-flex justify-content-center">
                    <div>
                        <img src="img/Energy Bars.png" style={{ width: "20vw", maxWidth: "100px" }} />
                    </div>
                </div>
                <div className="col d-flex justify-content-center">
                    <div>
                        <img src="img/Lightning Bolts.png" style={{ width: "15vw", maxWidth: "80px" }} />
                    </div>
                </div>
                <div className="col d-flex justify-content-center">
                    <div>
                        <img src="img/Turkish Amulet.png" style={{ width: "3vw", maxWidth: "90px" }} />
                        <img src="img/Turkish Amulet Blank.png" style={{ width: "3vw", maxWidth: "90px" }} />
                        <img src="img/Turkish Amulet Blank.png" style={{ width: "3vw", maxWidth: "90px" }} />
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col small-text-power">
                    <p style={{ color: "white" }}>Stealth</p>
                </div>
                <div className="col small-text-power">
                    <p style={{ color: "white" }}>Stamina</p>
                </div>
                <div className="col small-text-power">
                    <p style={{ color: "white" }}>Oracle <br />Wisdom</p>
                </div>
            </div>
        </div>
    );
}