import React from "react";

export default function RoundedComponent(props) {
    return (
        <div
            className={`m-0 ${props.className ?? props.className}`}
            style={{
                borderRadius: props.radius ? props.radius : "20px",
                color: "black",
                backgroundColor: props.color,
                ...props.style,
            }}
        >
            {props.children}
        </div>
    );
}
