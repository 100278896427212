import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function SiteMap() {
    return (
        <>
            <div className="col-md col-auto py-0 pr-2 border-right border-warning">
                <Link to={"/start#Roadmap"} className="text-white text-decoration-none">
                    Roadmap
                </Link>
                <br />
                <Link to={"/start#Team"} className="text-white text-decoration-none">
                    Team
                </Link>
                <br />
                <Link to={"/raritychart#Rarity-Chart"} className="text-white text-decoration-none">
                    Rarity Chart
                </Link>
                <br />
            </div>
            <div className="col-auto py-0 pr-5 text-left">
                <Link to={"/start#Mint"} className="text-warning text-decoration-none">
                    Buy a Teddy
                </Link>
            </div>
        </>
    );
}
