import React from "react";
import Rectangle from "../atoms/Rectangle";

export default function RoadmapRow(props) {
    const extraClasses = props.className ? props.className : "pt-4 pb-0";
    return (
        <tr className="text-p text-left">
            <td className="text-right border-top-0 pb-0 pl-md-5 pl-2 pt-md-3 pt-4">
                {props.percentage && <Rectangle />}
            </td>
            <td className={`text-info percentage iceberg align-baseline border-top-0 px-md-1 px-0 ${extraClasses}`}>
                {props.percentage}
            </td>
            <td className={`align-bottom border-top-0 align-baseline  ${extraClasses}`}>{props.children}</td>
        </tr>
    );
}
