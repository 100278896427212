import React from "react";
import InputBox from "../molecules/InputBox";
import SiteMap from "./SiteMap";

export default function Footer() {
    return (
        <footer className="row text-white align-items-center bg-black radius-lg mx-0">
            <div className="order-xl-1 order-2 col-md col-12 mx-auto align-self-end">
                <div className="row">
                    <div className="col-auto px-5 mx-md-0 mx-auto">
                        <img src="/img/bearlab.svg" style={{ maxWidth: "80vw", width: "315px" }} />
                        <div className="text-sm text-center pt-2 pr-2">All Rights reserved &copy; 2021</div>
                    </div>
                </div>
            </div>
            <div className="order-xl-2 order-1 col-xl-auto align-self-start col-12 d-block join-container">
                <div className="row">
                    <div className="col-auto mx-auto text-center bg-black radius-lg pt-lg-3 pt-3">
                        <div className="h4 iceberg px-5 mx-5">Join the Society.</div>
                        <div className="col-12 mx-md-auto mx-0 px-5 pt-3">
                            <InputBox type="email" placeholder="Insert e-mail..." />
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-3 col-md col-12 my-3 mx-auto d-block d-md-none">
                <div className="col-auto px-5 mx-md-0 mx-auto text-center">
                    <div className="row text-sm">
                        <div className="row mx-md-0 mx-auto text-sm text-right">
                            <SiteMap />
                        </div>
                    </div>
                    <img className="mt-4 mx-auto" src="/img/NFT.svg" width="90px" />
                </div>
            </div>
            <div className="order-3 col-md col-12 my-5  mx-auto text-right d-none d-md-block">
                <img className="mr-4 mb-3" src="/img/NFT.svg" />
                <div className="row text-sm">
                    <SiteMap />
                </div>
            </div>
            <div className="order-4 col-12 m-0 p-0 mt-3" style={{ backgroundColor: "#e7ff00", height: "15px" }}></div>
        </footer>
    );
}
