import React from "react";
import AspectRatio from "react-aspect-ratio";

export default function ImageWithFooter(props) {
    return (
        <div className="w-100 p-0 m-0 row">
            <AspectRatio ratio="1">
                <img
                    src={props.src}
                    className={`col-12 w-100 mx-0 ${props.className ? props.className : "px-2 pr-lg-3 pl-lg-3"}`}
                />
            </AspectRatio>
            {props.foot && <div className="col-12 px-0">{props.foot}</div>}
        </div>
    );
}
