import React from "react";
import Title from "../atoms/Title";

export default function RarityChartTitle() {
    return (
        <div className="mt-3 rarity-chart-bear" style={{ textAlign: "center" }}>
            <div>
                <Title />
                <h2 className="iceberg rarity-chart-logo" style={{ color: "#e7ff00", textTransform: "uppercase" }}>Rarity Chart</h2>
            </div>
            <img
                src="img/yellow-logo.svg"
                style={{ width: "6vw", maxWidth: "90px" }}
                className="bear-yellow"
            />
        </div>
    );
}