import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import MultilineText from "../atoms/MultilineText";

export default function SpecsTable(props) {
    const nElements = props.elements.length;
    const last = nElements - 1;
    const colSize = 80 / nElements;
    return (
        <table className="text-p table border-0 text-center">
            <thead>
                <tr>
                    {props.elements.map((value, index) => {
                        return (
                            <Fragment key={index}>
                                <th
                                    className="border-top-0 border-bottom-0 align-top px-0 py-0 h-specs"
                                    width={`${colSize}%`}
                                >
                                    <MultilineText text={value.top} />
                                </th>
                                {index !== last && <th className="border-top-0 border-bottom-0 py-0" />}
                            </Fragment>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {props.elements.map((value, index) => {
                        return (
                            <Fragment key={index}>
                                <td
                                    className="border-top-0 border-bottom-0 align-middle px-0 py-0"
                                    width={`${colSize}%`}
                                >
                                    <img src={value.src} className="w-100 px-md-2 px-1 my-md-2 my-0" />
                                </td>
                                {index !== last && (
                                    <td className="border-top-0 border-bottom-0 align-middle px-0 py-0">
                                        <FontAwesomeIcon icon="plus" color="#e7ff00" />
                                    </td>
                                )}
                            </Fragment>
                        );
                    })}
                </tr>
                <tr className="subtitle">
                    {props.elements.map((value, index) => {
                        return (
                            <Fragment key={index}>
                                <td
                                    className="border-top-0 border-bottom-0 align-middle px-0 py-0"
                                    width={`${colSize}%`}
                                >
                                    <div className="b-specs mx-auto text-center">
                                        <div>{`${value.bottom}`.toUpperCase()}</div>
                                    </div>
                                </td>
                                {index !== last && (
                                    <td className="border-top-0 border-bottom-0 align-middle px-0 py-0" />
                                )}
                            </Fragment>
                        );
                    })}
                </tr>
            </tbody>
        </table>
    );
}
