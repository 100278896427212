import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { readConfig } from "../../redux/blockchain/readConfig";
import RoundedComponent from "../atoms/RoundedComponent";
import Counter from "../molecules/CountDown";

export default function MintCountDown() {
    const [hasEnded, whenEnded] = useState(false);
    const [launchDate, setLaunchDate] = useState(undefined);
    useEffect(async () => {
        const CONFIG = await readConfig();
        setLaunchDate(CONFIG.LAUNCH_DATE ?? null);
    }, []);
    return (
        <ParentComponent isButton={hasEnded}>
            <RoundedComponent color="#B0F7E1" className="mx-lg-4 mx-md-1 align-middle btn-mint" radius="2vw">
                <div className="mx-auto d-flex px-md-5 py-md-3 pr-2 my-auto" style={{ maxWidth: "1200px" }} id="Mint">
                    {hasEnded ? (
                        <div className="d-block text-center mx-auto">
                            <div className="text-mint col-12 my-0 text-center pb-0 mb-0">MINT.</div>
                            <div className="col-12 b-specs mt-0 pt-0">[ Requires MetaMask wallet ]</div>
                        </div>
                    ) : (
                        <div className="my-auto w-100 row mx-auto">
                            <div className="text-mint col-auto my-0">MINT.</div>
                            {launchDate !== undefined && (
                                <div className={launchDate == null ? "mx-auto px-0" : "ml-auto px-0"}>
                                    <span className="text-mint" />
                                    {launchDate == null ? (
                                        <span className="title-2 my-0 iceberg pr-lg-4 pr-2 w-100">
                                            Coming soon...{" "}
                                        </span>
                                    ) : (
                                        <>
                                            <span className="text-live my-0 iceberg pr-lg-4 pr-2">Live in... </span>
                                            <Counter
                                                className="text-countdown my-0"
                                                style={{ color: "black" }}
                                                whenEnded={() => whenEnded(true)}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </RoundedComponent>
        </ParentComponent>
    );
}

function ParentComponent({ isButton, children }) {
    if (isButton) {
        return (
            <Link to={"/mint"}>
                <button className="btn w-100 px-0 border-0">{children}</button>
            </Link>
        );
    } else {
        return <>{children}</>;
    }
}
