import React, { Component, useEffect, useRef } from "react";
import Hls from "hls.js";

export default function Video({ videoSrc, duration, backgroundSrc, className, imgClassName }) {
    const video = useRef(null);

    useEffect(() => {
        startHLS(video.current);
    }, [video]);
    const loopVideo = (video) => {
        if (video.currentTime >= duration) {
            video.currentTime = 0;
        }
    };
    const startHLS = (video) => {
        if (video && videoSrc) {
            if (Hls.isSupported()) {
                console.log("using Hls");
                var hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);
            } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
                video.src = videoSrc;
            } else {
                console.log("Unable to play video");
            }
        }
    };
    return (
        <>
            <img className={`bg-video ${className ?? ""}`} src={backgroundSrc} />
            {videoSrc && (
                <video
                    autoPlay
                    loop
                    playsInline
                    muted
                    id="video"
                    className={`bg-video ${imgClassName ?? className ?? ""}`}
                    ref={video}
                    onTimeUpdate={() => loopVideo(video)}
                >
                    <source src={videoSrc} type="application/x-mpegURL" />
                </video>
            )}
        </>
    );
}
