import React, { Fragment } from "react";

export default function MultilineText(props) {
    const lines = props.text.split("\n");
    return (
        <>
            {lines.map((text, i) => {
                return (
                    <Fragment key={i}>
                        {text}
                        {i !== lines.length - 1 && <br />}
                    </Fragment>
                );
            })}
        </>
    );
}
