import React from "react";
import Separtator from "../atoms/Separtator";

export default function Greetings() {
    return (
        <div className="row text-center text-white w-100 mx-0 px-0">
            <div className="display-4 h1 col-12 mt-4 mt-xl-2 pt-xl-0 pt-md-2 mb-md-4 pb-md-1 pb-0 mb-2 text-warning title-2">
                Greetings,
            </div>
            <div className="w-100">
                <div className="d-lg-block d-none text-p">
                    BearLab. is an NFT Collection comprised of 9,999º unique pixel Teddies stored within the Ethereum
                    Blockchain.
                    <br /> Following <span className="text-warning">Monsieur-Croque`s vision</span> , certain [ TOP
                    SECRET ] issues will be completely Hand-Crafted, whilst
                    <br /> others will be Algorithmically Curated.
                </div>
                <div className="d-lg-none d-block pt-1 text-p">
                    BearLab. is an NFT Collection comprised of 9,999º unique pixel Teddies stored within the Ethereum
                    Blockchain. <div className="col-12 pt-3" />
                    Following <span className="text-warning"> Monsieur-Croque`s vision</span> , certain [ TOP SECRET ]
                    issues will be completely Hand-Crafted, whilst others will be Algorithmically Curated.
                </div>
                <Separtator className="col-12 mt-md-4 mt-3 d-md-none d-block " />
                <div className="text-p mt-xl-5 mt-3 mx-3">
                    Throughout this Collection, Monsieur-Croque devised BearLab. as a timeless Inception:{" "}
                    <div className="col-12 pt-md-0 pt-3" />
                    Alloying both, Real {"&"} Metaverse Cosmoi.
                </div>
                <img
                    src="img/yellow-logo.svg"
                    style={{ width: "5vw", maxWidth: "40px" }}
                    className="mt-md-2 mt-3 mx-auto bear-yellow"
                />
            </div>
        </div>
    );
}
