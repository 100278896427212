import React from "react";
import SelectPower from "../molecules/SelectPower";

export default function PowersFilter() {
    return (
        <div>
            <div>
                <div>
                    <div className="power-filter">
                        <p style={{ color: "white", textTransform: "uppercase", margin: "0px", marginLeft: "4%" }}>Fur</p>
                    </div>
                    <SelectPower />
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div className="power-filter">
                        <p style={{ color: "white", textTransform: "uppercase", margin: "0px", marginLeft: "4%" }}>Attire</p>
                    </div>
                    <SelectPower />
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div className="power-filter">
                        <p style={{ color: "white", textTransform: "uppercase", margin: "0px", marginLeft: "4%" }}>Accessory</p>
                    </div>
                    <SelectPower />
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div >
                        <div className="power-filter">
                            <p style={{ color: "white", textTransform: "uppercase", margin: "0px", marginLeft: "4%" }}>Backdrop</p>
                        </div>
                        <SelectPower />
                    </div>
                </div>
            </div>
        </div>
    );
}