import React from "react";
import MultilineText from "../atoms/MultilineText";

export default function TeamFooter(props) {
    return (
        <div className="text-white text-xs mt-md-2">
            <div className="b-specs text-warning text-name">{props.name}</div>
            <div className="col-12 pt-2" />
            <MultilineText text={props.role} />
            <div className="col-12 pt-2" />
            {props.extra && <MultilineText text={props.extra} />}
        </div>
    );
}
