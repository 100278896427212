import React from "react";
import RoundedComponent from "../atoms/RoundedComponent";
import Button from "../molecules/Button";

export default function DopeBearSeparator() {
    const msg = "Got a Dope Teddy?";
    return (
        <RoundedComponent color="#3a3e3d" radius="2vw">
            <div className="row px-lg-5 px-md-3 px-1 py-md-2 py-0 align-items-center mx-auto">
                <div className="col-12 py-0 my-0 text-center title-2 pb-md-2 pb-0 text-warning iceberg mx-auto d-md-none d-block">
                    {msg}
                </div>
                <div className="col py-1 my-0 text-center h2 text-warning iceberg d-md-block d-none mx-auto pb-4">
                    <div className="text-dope">{msg}</div>
                </div>

                {/*
                <div className="col-12 pb-2 px-0">
                    <div className="row w-100 mx-auto text-center mx-0 align-items-end">
                        <div className="col-auto pt-md-3">
                            <Button className={"px-md-5 px-sm-4 px-3"} href={"#"} radius="2vw">
                                <div className="text-btn text-dark" style={{ minWidth: "6rem" }}>
                                    CHECK RARITY
                                </div>
                            </Button>
                        </div>

                        <div className="col py-1 my-0 text-center h2 text-warning iceberg d-md-block d-none mx-auto pb-4">
                            <div className="text-dope">{msg}</div>
                        </div>

                        <div className="col d-md-none d-block" />
                        <div className="col-auto pt-md-3">
                            <Button className={"px-md-5 px-sm-4 px-3"} href={"#"} radius="2vw">
                                <div className="text-btn text-dark" style={{ minWidth: "6rem" }}>
                                    CERTIFICATE
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
                */}
            </div>
        </RoundedComponent>
    );
}
