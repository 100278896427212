import React from "react";
import RoundedComponent from "../atoms/RoundedComponent";

export default function BlurMint() {
    return (
        <div>
            <RoundedComponent color="#B0F7E1" className="mx-lg-4 mx-md-1 align-middle btn-mint" radius="2vw" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div>
                    <span className="title-2 my-0 iceberg pr-lg-4 pr-2 w-100">
                        Coming soon...{" "}
                    </span>
                </div>
            </RoundedComponent >
        </div >
    );
}