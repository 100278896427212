import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function CircleBtn(props) {
    return (
        <a
            role={"button"}
            href={props.href}
            target={"_blank"}
            className={`text-center d-block circle-nav ${props.className ? props.className : ""}`}
            style={{ borderRadius: "50%", backgroundColor: props.background }}
        >
            <div className="h-100 align-text-bottom text-center">
                {props.icon && <FontAwesomeIcon icon={props.icon} color={props.iconColor} style={{ height: "100%" }} />}
                {props.img && <img src={props.img} style={{ height: "100%" }} />}
            </div>
        </a>
    );
}
