import React, { Component } from "react";
import RarityCard from "../molecules/RarityCard";

export default function CardGridFour() {
    return (
        <div className="power-box-four">
            <div className="d-flex flex-wrap justify-content-center flex-wrap-img">
                {[...Array(4)].map((n) => <RarityCard className="flex-wrap-box-four" text="Teddy: 0,000º" />)}
            </div>
        </div>
    );
}