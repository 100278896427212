import React from "react";
import Powers from "../components/organisms/Powers"
import CardGridBottom from "../components/organisms/CardGridBottom"
import CardGridFour from "../components/organisms/CardGridFour"
import RarityChartTitle from "../components/organisms/RarityChartTitle";
import BlurMint from "../components/molecules/BlurMint";


export default function RarityChart() {
    return (
        <div id="Rarity-Chart">
            <div className="rarity-grid">
                <div className="rarity-right-col-top" style={{ margin: "0 auto" }}>
                    <div>
                        <RarityChartTitle />
                    </div>
                </div>
                <div className="rarity-left-col">
                    <div className="blur-mint" style={{ padding: "0 1.5%", position: "absolute", zIndex: "1" }}>
                        <BlurMint />
                    </div>
                    <div style={{ filter: "blur(5px)" }} >
                        <Powers />
                    </div>
                </div>
                <div className="rarity-right-col-bottom">
                    <CardGridFour />
                </div>
            </div >
            <CardGridBottom />
            <div className="text-center rarity-chart-loading">
                <p style={{ color: "#e7ff00" }}>●●●&nbsp;&nbsp;Loading_</p>
            </div>
        </div>
    );
}
