import React from "react";
import { Link } from "react-router-dom";
import RoundedComponent from "../atoms/RoundedComponent";

export default function Button(props) {
    return (
        <Link to={props.src ? props.src : ""}>
            <RoundedComponent
                className={`btn ${props.color ?? "btn-warning"} py-1 ${props.className ? props.className : "px-5"}`}
                radius={props.radius}
            >
                <span className="d-flex h-100" style={{ justifyContent: "center", flexDirection: "column" }}>
                    {props.children}
                </span>
            </RoundedComponent>
        </Link>
    );
}
