import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function PictureComparison({ left, right, icon, className }) {
    return (
        <div className="row align-items-center">
            <div className="col text-right">
                <img src={left} className="dollar-bill" />
            </div>
            <div className="col-auto px-0">
                <div className={`display-3 iceberg px-lg-4 px-0 ${className ?? ""}`}>
                    <div className="h-100">
                        <FontAwesomeIcon icon={icon} size="xs" className="py-lg-2 py-3" />
                    </div>
                </div>
            </div>
            <div className="col">
                <img src={right} className="dollar-bill" />
            </div>
        </div>
    );
}
