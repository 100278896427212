import React, { Component } from "react";
import Title from "../components/atoms/Title";
import Video from "../components/atoms/Video";
import Button from "../components/molecules/Button";

export default function StartingView() {
    return (
        <div className="d-flex flex-1 start-container">
            <Video
                videoSrc="/img/billboard/billboard.m3u8"
                duration={31870}
                backgroundSrc="/img/video.jpg"
                className="main-video"
            />
            <div className="text-center py-5 mx-0 w-100 row flex-column start-flex">
                <div className="col-auto">
                    <div className="pt-md-5 pb-0 title-main">
                        <Title />
                        <img src="/img/yellow-logo.svg" className="pb-3" style={{ width: "10vw", maxWidth: "86px" }} />
                    </div>
                </div>
                <div className="col-auto mb-5 mt-3">
                    <Button className="px-4" src="/start">
                        <span className="mt-1 px-3 start-btn">START</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}
