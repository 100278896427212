import React, { Component } from "react";
import RarityCard from "../molecules/RarityCard";

export default function CardGridBottom() {
    return (
        <div className="power-box-bottom">
            <div className="d-flex flex-wrap justify-content-center flex-wrap-img">
                {[...Array(12)].map((n) => <RarityCard className="flex-wrap-box" text="Teddy: 0,000º" />)}
            </div>
        </div>
    );
}

