import React from "react";

export default function RarityCard({ className, text }) {
    return (
        <div className={className}>
            <img className="flexSize" src="/img/Poker_Card.png" />
            <p className="iceberg text-center rarity-chart-descr text-white">{text}</p>
        </div>
    );
}
