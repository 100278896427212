import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import RoundedComponent from "../atoms/RoundedComponent";
import emailjs from "@emailjs/browser";

export default function InputBox(props) {
    const [mail, setMail] = useState("");
    const [validMail, setValidMail] = useState(false);
    const [blocked, setBlocked] = useState(false);
    return (
        <RoundedComponent color="#FBF79B">
            <div className="input-group">
                {validMail && (
                    <div className="input-group-append btn-mail">
                        <div
                            className="btn"
                            onClick={async () => {
                                setBlocked(true);
                                if (await sendMail(mail)) {
                                    setMail("Done!");
                                } else {
                                    setMail("Ups!");
                                }
                            }}
                            disabled={blocked}
                        >
                            <FontAwesomeIcon icon="paper-plane" color="transparent" />
                        </div>
                    </div>
                )}
                <input
                    placeholder={props.placeholder}
                    type={props.type}
                    className="form-control border-0"
                    style={{ backgroundColor: "transparent", color: "black", textAlign: "center" }}
                    onChange={(event) => {
                        setMail(event.target.value);
                        setValidMail(validateEmail(event.target.value));
                    }}
                    value={mail}
                />
                {validMail && (
                    <div className="input-group-append btn-mail">
                        <button
                            className="btn"
                            onClick={async () => {
                                setBlocked(true);
                                if (await sendMail(mail)) {
                                    setMail("Done!");
                                } else {
                                    setMail("Ups!");
                                }
                            }}
                            disabled={blocked}
                        >
                            <FontAwesomeIcon icon="paper-plane" />
                        </button>
                    </div>
                )}
            </div>
        </RoundedComponent>
    );
}

const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const sendMail = async (email) => {
    const res = await emailjs.send(`service_rocwwtg`, `template_l9o1rja`, { email: email });
    return res.status < 400;
};
