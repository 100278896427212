import React, { Component } from "react";
import Separtator from "../components/atoms/Separtator";
import Greetings from "../components/organisms/Greetings";
import MintCountDown from "../components/organisms/MintCountDown";
import ResponsiveContainer from "../components/atoms/ResponsiveContainer";
import Lore from "../components/organisms/Lore";
import ImagePattern from "../components/atoms/ImagePattern";
import NTFSeparator from "../components/organisms/NTFSeparator";
import Specs from "../components/organisms/Specs";
import Title from "../components/atoms/Title";
import RoadMap from "../components/organisms/RoadMap";
import DopeBearSeparator from "../components/organisms/DopeBearSepartor";
import TheTeam from "../components/organisms/TheTeam";
import Video from "../components/atoms/Video";

class MainView extends Component {
    render() {
        return (
            <div>
                <div className="all-screen">
                    <div>
                        <ResponsiveContainer>
                            <div className="row col-xl-4 mb-2 pt-xl-0 pt-3">
                                <div className="mt-3 px-xl-0 px-md-5 px-3">
                                    <Title />
                                </div>
                            </div>
                        </ResponsiveContainer>
                        <div className="position-relative">
                            <Video
                                videoSrc="/img/banner/bear_laboratory.m3u8"
                                backgroundSrc="/img/banner.png"
                                className="banner"
                                imgClassName="bg-banner"
                                duration={3.2}
                            />
                        </div>
                    </div>
                    <ResponsiveContainer className="px-4">
                        <div className="row mx-auto w-100">
                            <Greetings />
                            <Separtator className="col-12 mt-md-2 mb-md-2 mb-2" />
                        </div>
                    </ResponsiveContainer>
                </div>
                <ResponsiveContainer>
                    <div className="row">
                        <div className="col-12">
                            <img src="/img/box.gif" className="p-absolute box" />
                            <MintCountDown />
                        </div>
                    </div>
                    <Lore />
                </ResponsiveContainer>
                <NTFSeparator />
                <ResponsiveContainer>
                    <Specs />
                </ResponsiveContainer>
                <DopeBearSeparator />
                <ResponsiveContainer>
                    <RoadMap />
                    <TheTeam />
                </ResponsiveContainer>
                <div className="row px-2 py-md-3 pb-3">
                    <ImagePattern src={"/img/x-logo.png"} height="150px" className="d-xl-block d-none" />
                    <ImagePattern src={"/img/x-logo-md.png"} height="98px" className="mb-md-5 d-xl-none d-block" />
                </div>
            </div>
        );
    }
}

export default MainView;
