import React from "react";
import ImageWithFooter from "./ImageWithFooter";

export default function ImageRow(props) {
    return (
        <div className="row mx-auto text-center mx-0 px-0">
            {props.images.map((imageProps, i) => {
                return (
                    <div className="col my-2 mx-auto px-0" key={i}>
                        <ImageWithFooter src={imageProps.src} foot={imageProps.foot} className={props.imgClassName} />
                    </div>
                );
            })}
        </div>
    );
}
