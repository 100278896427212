import React from "react";
import ImagePattern from "../atoms/ImagePattern";
import QMarks from "../atoms/QMarks";
import RoundedComponent from "../atoms/RoundedComponent";
import Button from "../molecules/Button";

export default function NTFSeparator() {
    return (
        <RoundedComponent color="#3a3e3d" radius="2vw">
            <div className="row w-100 mx-0 px-0 py-md-2 py-1 align-items-center">
                <div className="col px-0">
                    <div className="pl-lg-2">
                        <QMarks />
                    </div>
                </div>
                <div className="col-auto py-1 my-0 px-lg-2 px-0">
                    <Button className="px-md-5 px-3 text-p py-2" src="/nft" radius="2vw">
                        What is an NFT?
                    </Button>
                </div>
                <div className="col px-0">
                    <div className="pr-lg-2">
                        <QMarks />
                    </div>
                </div>
            </div>
        </RoundedComponent>
    );
}
