import React from "react";
import SectionHeader from "../atoms/SectionHeader";
import ImageRow from "../molecules/ImageRow";
import TeamFooter from "../molecules/TeamFooter";

export default function TheTeam() {
    return (
        <div className="row py-md-0 py-2" id="Team">
            <SectionHeader>The team.</SectionHeader>
            <div className="px-3">
                <div className="col-12 text-p text-center pt-4 mb-4 mb-md-2">
                    Brought {"&"} Curated by a dedicated team of Metaverse Enthusiasts and an Underground Artist, all
                    United by a Secret Society.
                    <div className="col-12 py-md-0 py-3" />
                    Devoted to their Community, BearLab. seeks to create{" "}
                    <div className="col-12 py-md-0 d-block d-md-none" />
                    Private Experiences for their Members.
                </div>
                <div className="col-lg-3 col-md-4 col-sm-5 col-6 mx-auto">
                    <ImageRow
                        images={[
                            {
                                src: "/img/Monsieur-Croque.png",
                                foot: (
                                    <TeamFooter
                                        name="Monsieur-Croque."
                                        role="Unidentified  Pop-Architect."
                                        extra={"Yes, he is French...\n[Or, is he?]."}
                                    />
                                ),
                            },
                        ]}
                        imgClassName="px-md-4 px-5 py-md-3 py-0"
                    />
                </div>
                <div className="col-xl-11 col-lg-10 col-12 mx-auto px-md-1 px-1">
                    <ImageRow
                        images={[
                            {
                                src: "/img/Dr.png",
                                foot: <TeamFooter name="Fouquet-de-Chantilly" role="Chromatic Alchemist" />,
                            },
                            {
                                src: "/img/Richard.png",
                                foot: <TeamFooter name="R-Biscuit" role="Blockchain Guru" />,
                            },
                            {
                                src: "/img/Mr.png",
                                foot: <TeamFooter name="Mr. Camus" role="Metaverse Visionary" />,
                            },
                            {
                                src: "/img/Angel-X.png",
                                foot: <TeamFooter name="Tomokana-San" role="Business Tycoon" />,
                            },
                            {
                                src: "/img/Madame_Celine.png",
                                foot: <TeamFooter name="Madame-Céline" role="Digital-Art Connoisseur" />,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}
