import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import "./styles/bootstrap.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAnchor,
    faChevronLeft,
    faEquals,
    faNotEqual,
    faPaperPlane,
    faPlus,
    fas,
} from "@fortawesome/free-solid-svg-icons";
import { fab, faDiscord, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./styles.css";
import { BrowserRouter } from "react-router-dom";
import { init } from "@emailjs/browser";
import App from "./App";

library.add(
    fas,
    fab,
    faDiscord,
    faChevronLeft,
    faTwitter,
    faInstagram,
    faAnchor,
    faPlus,
    faPaperPlane,
    faNotEqual,
    faEquals
);

init("user_2rKh5R8fIt7KfDGDPdJtV");
//init("user_SARcSBhb2kIEXz9Acn3tZ");

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
