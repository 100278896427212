import React from "react";
import SectionHeader from "../atoms/SectionHeader";
import SpecsTable from "../molecules/SpecsTable";

export default function Specs() {
    return (
        <div className="row">
            <SectionHeader>Specs.</SectionHeader>
            <div className="col-12 px-md-5">
                <div className="mx-auto mt-2 px-md-5 px-3" style={{ maxWidth: "1000px" }}>
                    <div className="mx-lg-5 mx-xl-0 px-xl-0 px-lg-5">
                        <SpecsTable
                            elements={[
                                {
                                    top: "Caramel Brown",
                                    src: "/img/Fur.png",
                                    bottom: "fur",
                                },
                                {
                                    top: "Marinière Pull",
                                    src: "/img/attire.png",
                                    bottom: "attire",
                                },
                                {
                                    top: "German Beer\nStein",
                                    src: "/img/German_Beer_Stein.png",
                                    bottom: "accessories",
                                },
                                {
                                    top: "Neon Rosé",
                                    src: "/img/Neon_Rose.png",
                                    bottom: "backdrop",
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 text-center text-p pb-xl-5 mt-3 mb-4">
                Experimental Bio-Products, each <span className="text-warning">Teddy</span> is unique within its own
                Cultural Cosmos.
                <div className="col-12 my-0 py-2 py-lg-3" />
                Some Teddies might have more defined personalities than others, however, they are all conceived from 4
                unique features:
                <div className="col-12 my-0 py-2 py-lg-0" />
                FUR, ATTIRE, ACCESSORIES, BACKDROP.
            </div>
        </div>
    );
}
