import React, { useEffect } from "react";
import ResponsiveContainer from "../components/atoms/ResponsiveContainer";
import Title from "../components/atoms/Title";
import SectionHeader from "../components/atoms/SectionHeader";
import PictureComparison from "../components/molecules/PictureComparison";
import Button from "../components/molecules/Button";
import LineBreak from "../components/atoms/LineBreak";

export default function NFTView() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="text-white  text-p">
            <ResponsiveContainer>
                <img src="/img/nft_metamask.png" className="nft-meta" />
                <div className="row col-xl-4 mb-2 pt-xl-5 mt-2 pt-3">
                    <div className="mt-3 px-xl-0 px-md-5 px-3">
                        <Title />
                    </div>
                </div>
                <div className="text-center">
                    <div className="h2 mt-2 mb-4 iceberg title-3 text-warning">WHAT is an NFT?</div>
                    <div>
                        An <span className="text-warning">NFT</span>,{" "}
                        <span className="text-warning">[ Non-Fungible Token ]</span> is a unique, unalterable, and
                        irreplaceable asset
                        <LineBreak size="xs" /> that lives within a certain{" "}
                        <span className="text-warning">Blockchain</span>.
                    </div>
                    <img src="/img/quem.png" className="mt-3 mb-0 quem-img" />
                </div>
                <SectionHeader className="mt-0 pt-0 mb-0 pb-0">example.</SectionHeader>
                <div className="text-center mb-lg-3 mb-1">
                    Take, for instance, 2 Identical Dollar Bills:
                    <div className="col-12 py-0" />
                    <br /> It wouldn’t matter which Bill you’d choose, any would hold
                    <LineBreak size="xs" limit="lg" /> the same value {"&"} identical properties.
                    <br />
                    :. Thus, they are <span className="text-warning">Fungible</span>.
                </div>
                <PictureComparison
                    left="/img/dollar-bill.png"
                    right="/img/dollar-bill.png"
                    icon="equals"
                    className="text-warning"
                />
                <div className="text-center mt-lg-4 mt-0 mb-lg-5 mb-0">
                    Now, let’s picture Actor {"&"} 40th President of the U.S. Ronald Reagan’s autographed Dollar Bill.
                    <LineBreak size="xs" />
                    Surely, that Bill wouldn’t hold the same value it had, <LineBreak size="xs" limit="lg" />
                    prior to being autographed, Right?
                    <div className="col-12 py-0" />
                    <br />
                    The signed Bill became completely unique:
                    <LineBreak size="xs" limit="lg" />
                    Due to its Scarcity {"&"} Rarity, its perceived value increased.
                    <br />
                    :. Hence, it became <span className="text-warning">Non-Fungible</span>.
                </div>
                <PictureComparison
                    left="/img/dollar-bill-signed.png"
                    right="/img/dollar-bill.png"
                    icon="not-equal"
                    className="text-warning"
                />
                <div className="text-center mt-lg-5 mt-1 mb-5 pb-4">
                    <span className="text-warning">NFT</span>s are not just JPEGs, music, or a stored combination of 0’s{" "}
                    {"&"} 1’s:
                    <br />
                    They are <span className="text-warning">Contracts of Ownership</span> that will live Forever.
                    <LineBreak size="xs" className="mb-lg-0 pb-lg-1 mb-4 pb-2" />
                    Because they’re not replaceable, once MINTED, <LineBreak size="xs" limit="lg" />
                    they can only be Traded: Never Re-Created.
                    <LineBreak size="xs" />
                    As you see, NFTs Democratize the Right of Ownership, <LineBreak size="xs" limit="lg" />
                    allowing for the acquisition of Intellectual Property <LineBreak size="xs" limit="lg" />
                    in a matter of seconds.
                    <LineBreak size="xs" className="mb-lg-0 pb-lg-1 mb-2 pb-2" />
                    Since the <span className="text-warning">
                        Contract is embedded within the Ethereum Blockchain
                    </span>, <LineBreak size="xs" limit="lg" />
                    it is, and will remain Secure.
                </div>
                <div className="text-center mb-4">
                    No Governments or Notaries required.
                    <br />
                    As simple as that.
                    <div className="col-12 py-3" />
                    <Button className="px-5 mb-lg-5 text-p" color="btn-info" src="/start#Mint" radius="11px">
                        <span className="alpha-slab px-4 py-1">MINT</span>
                    </Button>
                </div>
            </ResponsiveContainer>
        </div>
    );
}
