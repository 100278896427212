import React from "react";
import PowersFilter from "../organisms/PowersFilter";
import GraphicPower from "../molecules/GraphicPower";
import SerialID_Respawn from "../molecules/SerialID_Respawn";

export default function Powers() {
    return (
        <div className="power-box">
            <p className="power-filter text-center" style={{ color: "#e7ff00", textTransform: "uppercase", textDecoration: "line-through" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Powers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <GraphicPower />
            <div className="power-grid">
                <div className="power-grid-left">
                    <div>
                        <PowersFilter />
                    </div>
                </div>
                <div className="power-grid-right">
                    <SerialID_Respawn placeholder="Serial ID..." />
                </div>
                <div className="power-grid-bottom">
                    <div className="text-center small-text-power" style={{ color: "white", width: "100%" }}>
                        <p style={{ textTransform: "uppercase" }}><br />*Note*</p>
                        <p>Please  CLICK  your avatar to further inspect<br />their  Rarity  Powers.  </p>
                    </div>
                </div>
            </div>

        </div>
    );
}